import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

import NavBar from "../../../NavBar";
// import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import history from "../../../../utils/history";
import InfoModalText from "../../../../modals/InfoModalText";
import SiReportModal from "../../../../modals/SiReportModal";
import CommunityModal from "../../../../modals/CommunityModal";
import FeedbackModal from "../../../../modals/FeedbackModal";
import FAQ from "../../../../Components/GeneralUI/MenuFaq";

const navbarModals = [
  <InfoModalText />,
  <SiReportModal />,
  <CommunityModal />,
  <FeedbackModal />,
  <FAQ />,
  // <BrowserRouter history={history}>
  <NavBar />,
  // </BrowserRouter>,
];

const variants = {
  open: {
    opacity: 1,
    y: 50,
    transition: { staggerChildren: 0.07, delayChildren: 0.5 },
  },
  closed: {
    opacity: 0,
    y: -10000,
    transition: { delay: 3.5, staggerChildren: 0.09, staggerDirection: -10000 },
  },
};

export const Nav = (isOpen) => {
  //   if (isOpen.isOpen) {
  return (
    <motion.ul variants={variants}>
      {navbarModals.map((text, i) => (
        <MenuItem index={i} isOpen={isOpen} text={text} key={i} />
      ))}
      {/* <BrowserRouter history={history}>
        <NavBar />
      </BrowserRouter> */}
    </motion.ul>
  );
  //   }
};
