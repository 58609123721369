import "../../styles/SiWord/SiRows.css";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { SiWordContext } from "./SiWord";

const column_arr = [0, 1, 2, 3, 4, 5, 6];

const SiRow = ({ guess, myCurGuess, guessIndex }) => {
  const {
    guessIsCorrect,
    opacity,
    guessOnceClicked,
    animationFinished,
    wordExists,
    currentRowTry,
  } = useContext(SiWordContext);
  const newOpacity = opacity;

  let current_row = 0;

  if (guessIsCorrect) {
    current_row = currentRowTry - 1;
  }

  if (guess) {
    return (
      <div
        className="si_row"
        id={
          guessIndex === current_row &&
          guessIsCorrect &&
          animationFinished &&
          "game_is_finished"
        }
      >
        {guess.map((letter, index) => {
          return (
            <motion.div key={index} className={letter.colour_state}>
              {letter.key}
            </motion.div>
          );
        })}
      </div>
    );
  }

  if (myCurGuess) {
    let letters = myCurGuess.split("");

    return (
      <div className="si_row">
        {letters.map((letter, i) => (
          <motion.div
            key={i}
            initial={{ opacity: newOpacity, scale: 1 }}
            animate={{ opacity: 1, scale: [1.1, 1] }}
            transition={{ duration: 0.2 }}
          >
            {letter}
          </motion.div>
        ))}
        {[...Array(7 - letters.length)].map((_, i) => (
          <motion.div
            initial={{ opacity: newOpacity, scale: 1 }}
            key={i}
          ></motion.div>
        ))}
      </div>
    );
  }
  

  return (
    <div className="si_row">
      {column_arr.map((e, i) => {
        return (
          <motion.div
            initial={!guessOnceClicked && { opacity: 0, scale: 0 }}
            animate={{ opacity: newOpacity, scale: 1 }}
            transition={{ duration: 1 }}
            key={i}
          ></motion.div>
        );
      })}
    </div>
  );
};

export default SiRow;
