import classes from "../../styles/GeneralUI/GameModalSubHeader.module.css";

const GameModalSubHeader = ({
  icon,
  children,
  subheader,
  style,
  pStyle,
  sub_container_style,
  text,
  sub_header_visible,
  anotherSubHeaderValue,
}) => {
  return (
    <div
      className={
        sub_header_visible && classes.welcome_modal_elements__container
      }
      style={style}
    >
      <div
        style={sub_container_style}
        className={
          icon
            ? classes.welcome_modal_sub_elements_container_withIcon
            : classes.welcome_modal_sub_elements_container
        }
      >
        <p
          className={icon ? classes.sub_header_withIcon : classes.sub_header}
          style={pStyle}
        >
          {subheader}
        </p>
        {children}
      </div>
    </div>
  );
};

export default GameModalSubHeader;
