import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faBrain } from "@fortawesome/free-solid-svg-icons";
import AppButton from "../Components/GeneralUI/AppButton";
import { useAuth0 } from "@auth0/auth0-react";

const StatNonRegisterStats = (props) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <>
      <div className="stat_non_register__statistics_withicons_container">
        <div className="stat_non_register__icon_text_container">
          <FontAwesomeIcon
            className="stat_non_register__icon"
            color="green"
            fontSize="50px"
            icon={faBrain}
          />
          <p className="stat_non_register__text">Played: </p>
          <p className="stat_non_register__text__number">
            {props.played_games}
          </p>
        </div>

        <div className="stat_non_register__icon_text_container">
          <FontAwesomeIcon
            className="stat_non_register__icon"
            color="gold"
            fontSize="50px"
            icon={faTrophy}
          />
          <p className="stat_non_register__text">Won: </p>
          <p className="stat_non_register__text__number">{props.win_rate}</p>
        </div>
      </div>
      {!isAuthenticated && (
        <div className="register_modal__AppButton">
          <AppButton
            styleButton={{
              backgroundColor: "#A2AB28",
              height: "46px",
              padding: "1rem",
            }}
            styleP={{
              color: "#D9D9D9",
              color: "white",
              fontSize: "19px",
            }}
            onClick={() => loginWithRedirect()}
          >
            Log in to see stats
          </AppButton>
        </div>
      )}
    </>
  );
};

export default StatNonRegisterStats;
