import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import GameModal from "../Components/GeneralUI/GameModal";

import siQuizClasses from "../styles/GeneralUI/SiQuizModal.module.css";
import ClueLettersBox from "../Components/GeneralUI/Header/ClueLettersBox";
import ModalSubHeaderText from "../Components/GeneralUI/ModalSubHeaderText";
import ModalFooter from "../Components/GeneralUI/ModalFooter";
import AppButton from "../Components/GeneralUI/AppButton";
import gameModalClasses from "../styles/GeneralUI/GameModal.module.css";

import thirdImage from "../assets/gifs/3.gif";

import { QuizContext } from "../Components/SiQuiz/Quiz";
import { AppContextProvider } from "../Home";
import playIcon from "../assets/Play.png";
import GameModalSubHeader from "../Components/GeneralUI/GameModalSubHeader";
import { useContext } from "react";

import ReactGA from "react-ga4";
const useAnalyticsEventTracker = (category = "Start word part") => {
  const eventTracker = (action = "Start game", label = "Start game main") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};

const addDatatoLocalStorage = (localData) => {
  localStorage.setItem("questions_end", localData);
};

const Slideshowgame = (onClick, className) => {
  const images = [thirdImage];

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: true,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
    autoplay: false,
  };

  const { correctAnswerCount, quizIsFinished, clueLettersBoard } = useContext(QuizContext);
  const { setQuizEnded } = useContext(AppContextProvider);

  const startSiGameHandler = () => {
    // onHandleGettingFilledClueLetters(clueLettersBoard);
    setTimeout(() => {
      addDatatoLocalStorage(correctAnswerCount);
      setQuizEnded(true);
    }, 500);
  };

  return (
    <Slide {...properties}>
      <div className={siQuizClasses.modal_container}>
        <div className={`${siQuizClasses.backdrop} ${className}`} onClick={onClick} />
        <div className={siQuizClasses.modal}>
          <GameModal className={quizIsFinished && siQuizClasses.backdrop}>
            <div className={siQuizClasses.siQuiz_modal__container}>
              <div className={siQuizClasses.siQuizModal_header_container}>
                {correctAnswerCount !== 0 ? (
                  <p className={siQuizClasses.siQuizModal_header}>
                    Bravo! You have {correctAnswerCount + 1} SiClues now
                  </p>
                ) : (
                  <p className={siQuizClasses.siQuizModal_header}>
                    Oops! We haven't got clue letter :(
                  </p>
                )}
              </div>
              <div className={siQuizClasses.clue_letters_box_inmodal__container}>
                <div className={siQuizClasses.clue_letters_box_inmodal}>
                  <ClueLettersBox className={siQuizClasses.clue_lettter_box_inmodal} />
                </div>
              </div>
              {/* <div> */}
              <GameModalSubHeader style={{ marginTop: "2rem" }} icon={true}>
                <ModalSubHeaderText icon="7️⃣">
                  <span>
                    Find the <span style={{ color: "#d76502" }}>SiWord</span> in{" "}
                    <span style={{ color: "red" }}>7</span> tries.
                  </span>
                </ModalSubHeaderText>
                <ModalSubHeaderText icon="🔠">
                  <span>
                    Try a <span style={{ color: "red" }}>valid </span> 7-letter word and hit the
                    enter button.
                  </span>
                </ModalSubHeaderText>
                <ModalSubHeaderText icon="🟠">
                  <span>
                    <span style={{ color: "#d76502" }}>Orange</span> letter is in the{" "}
                    <span style={{ color: "#d76502" }}>SiWord</span>, but in the wrong place.
                  </span>
                </ModalSubHeaderText>
                <ModalSubHeaderText icon="☺️">
                  No worries, we will help you through.
                </ModalSubHeaderText>
                <ModalSubHeaderText icon="🗞">
                  <span>
                    Don’t forget to read the <span style={{ color: "#d76502" }}>SiStory</span>.
                  </span>
                </ModalSubHeaderText>
              </GameModalSubHeader>

              <div className={gameModalClasses.button_container} style={{ marginTop: "2rem" }}>
                <div className={gameModalClasses.lets_siword_button__container}>
                  <AppButton
                    icon={playIcon}
                    onClick={startSiGameHandler}
                    styleButton={{
                      backgroundColor: "#A2AB28",
                    }}
                    styleP={{ color: "#D9D9D9" }}
                  >
                    Let’s SiWord!
                  </AppButton>
                </div>
              </div>
            </div>
          </GameModal>
        </div>
      </div>
      <div className="each-slide-effect each-slide-effect-3 each-slide-effect-3-modal">
        <div style={{ backgroundImage: `url(${images[0]})` }}></div>
      </div>
    </Slide>
  );
};

export default Slideshowgame;
