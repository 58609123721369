import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import secondIcon from "../assets/2-1.png";
import TitleModal from "./TitleModal";
import SubheaderText from "./SubHeaderTextModal";
import checkIcon from "../assets/checkIcon.png";
import "../styles/ModalStyles/InfoModalText.css";
import AppButton from "../Components/GeneralUI/AppButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "25px",
    backgroundColor: "#BEBDBD !important",
    overflow: "unset !important",
  },
  "& .MuiDialogContent-root": {
    paddingTop: "0",
    paddingBottom: "0",
    // textAlign: 'center'
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiButtonBase-root": {
    position: "absolute !important",
    right: "-10px !important",
    top: "-10px !important",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{
            backgroundColor: "#3565A3",
            border: "1.6px solid #D9D9D9",
            color: "#D9D9D9",
            fontSize: "50px",
          }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fontSize: "20px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p onClick={handleClickOpen}>How the SiReward works?</p>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} align="center">
          <TitleModal className={"info_modal_text__title"}>How the SiReward works?</TitleModal>
        </BootstrapDialogTitle>
        <DialogContent>
          <SubheaderText classNameText={"info_modal_text__subheader_text"} icon={checkIcon}>
            The cycle begins from the first day.
          </SubheaderText>
          <SubheaderText classNameText={"info_modal_text__subheader_text"} icon={checkIcon}>
            Every 7th day of the cycle the names of the 7 best SiPlayers are published.
          </SubheaderText>
          <SubheaderText classNameText={"info_modal_text__subheader_text"} icon={checkIcon}>
            Every 21st day of the cycle the names of the 21 best SiPlayers are published.
          </SubheaderText>
          <SubheaderText classNameText={"info_modal_text__subheader_text"} icon={checkIcon}>
            Every 42nd day of the cycle the name of the best SiPlayer is published and the cycle
            ends and the new cycle begins
          </SubheaderText>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
