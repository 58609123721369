import { useContext } from "react";
import { QuizContext } from "../Quiz";
import "../../../styles/SiQuiz/VariantLetter.css";

const VariantLetter = ({ vLetter }) => {
  const { selectedAnswer } = useContext(QuizContext);
  let colour_state = "";

  if (vLetter === selectedAnswer) {
    colour_state = "active_letter";
  } else {
    colour_state = "variant_letter";
  }

  // console.log("colour state:", colour_state);

  return <p className={colour_state}>{vLetter}.</p>;
};

export default VariantLetter;
