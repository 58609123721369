import "../styles/ModalStyles/InfoModal.css";

const SubheaderText = ({
  children,
  icon,
  classNameDiv,
  classNameText,
  iconStyle,
}) => {
  return (
    <div className={`${"text_icon__container"} ${classNameDiv}`}>
      {icon && <img className={`${"icon"} ${iconStyle}`} src={icon} />}
      <p
        id={!icon ? "without_icon" : {}}
        className={`${"text"} ${classNameText}`}
      >
        {children}
      </p>
    </div>
  );
};

export default SubheaderText;
