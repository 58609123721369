import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import secondIcon from "../assets/2-1.png";
import ButtonLink from "../inputs/ButtonLink";
import SubheaderText from "./SubHeaderTextModal";
import TitleModal from "./TitleModal";
import "../styles/ModalStyles/CommunityModal.css";
import AppButton from "../Components/GeneralUI/AppButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "25px",
    backgroundColor: "#BEBDBD !important",
    overflow: "unset !important"
  },
  "& .MuiDialogContent-root": {
    paddingTop: "0",
    paddingBottom: "0",
    // textAlign: 'center'
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },

  "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
    marginLeft: "1rem",
    color: "#3565A3",
    fontSize: "18px",
  },
  "& .MuiButtonBase-root":{
    position: "absolute !important",
    right: "-10px !important",
    top: "-10px !important"

  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{
            backgroundColor: "#3565A3",
            border: "1.6px solid #D9D9D9",
            color: "#D9D9D9",
            fontSize: "50px",
          }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fontSize: "20px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p onClick={handleClickOpen}>Join the community</p>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          align="center"
        >
        <TitleModal>Join the community</TitleModal>
        </BootstrapDialogTitle>
        <DialogContent>
          <SubheaderText
            classNameDiv="community_modal__subheader_container"
            classNameText="community_modal__subheader__text"
          >
            You can follow us on Facebook and Twitter:
          </SubheaderText>
          <SubheaderText>
            <ButtonLink
              button_title="Join Us on Facebook"
              button_link="https://www.facebook.com/profile.php?id=100089348485304"
              icon_names="FacebookIcon"
            />
          </SubheaderText>
          <SubheaderText>
            <ButtonLink
              button_title="Follow Us on Twitter"
              button_link="https://twitter.com/SiWord_"
              icon_names="TwitterIcon"
            />
          </SubheaderText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
