import * as React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import "../styles/ModalStyles/RegisterModal.css";
import SubheaderText from "./SubHeaderTextModal";
import checkIcon from "../assets/checkIcon.png";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import thirdIcon from "../assets/3-1.png";
import { useAuth0 } from "@auth0/auth0-react";

import "../styles/ModalStyles/StatisticsModalNonRegister.css";
import StatNonRegisterStats from "./StatNonRegisterStats";
import AppButton from "../Components/GeneralUI/AppButton";
function createData(name, games, streak, rate) {
  return { name, games, streak, rate };
}

const rows = [
  createData("User 1", 259, 35, 79),
  createData("User 2", 237, 23, 72),
  createData("User 3", 123, 15, 46),
  createData("User 4", 65, 8, 23),
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FullScreenDialog() {
  const [open, setOpen] = React.useState(false);

  const { loginWithRedirect } = useAuth0();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <img src={thirdIcon} onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="stat_non_register__header">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon style={{ fontSize: "25px" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, fontSize: "1.6rem" }}
              variant="h6"
              component="div"
            >
              Statistics
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                sx: {
                  height: 4,
                },
              }}
              sx={{
                "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
                  color: "#3565A3",
                  fontFamily: "Nunito",
                  fontSize: "1.5rem",
                  fontWeight: "900",
                  textTransform: "none",
                },
              }}
            >
              <Tab label="Statistics of Today" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* STATISCS ARE LOCATED IN THIS COMPONENT */}
            <StatNonRegisterStats />
          </TabPanel>
        </Box>
      </Dialog>
    </div>
  );
}
