import { useTimer } from "react-timer-hook";
import classes from "../../styles/GeneralUI/NextGameTimer.module.css";

const NextGameTimer = ({ expiryTimestamp }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => alert("Refresh to play the new SiGame"),
  });

  let hour = hours;
  let min = minutes;
  let sec = seconds;

  if (sec.toString().length === 1) {
    sec = "0" + sec;
  }

  if (hour.toString().length === 1) {
    hour = "0" + hour;
  }

  if (min.toString().length === 1) {
    min = "0" + minutes;
  }

  return (
    <div className={classes.game_timer}>
      <div className={classes.timer_text}>
        Next <span style={{ color: "#d76502" }}>SiWord</span> starts after:
      </div>
      <span className={classes.time_element}>
        {hour}:{min}:{sec}
      </span>
    </div>
  );
};

export default NextGameTimer;
