import { useContext } from "react";
import "../../../styles/SiQuiz/VariantText.css";
import { QuizContext } from "../Quiz";

const VariantText = ({ vText, vLetter }) => {
  const { selectedAnswer } = useContext(QuizContext);
  let colour_state = "";
  let text_size_state = "";

  
  if (vText.length > 25) {
    text_size_state = "text_font_init";
  }
  if (vText.length > 57) {
    text_size_state = "text_font_medium";
  }
  if (vText.length > 75) {
    text_size_state = "text_font_last";
  }

  if (vLetter === selectedAnswer) {
    colour_state = "active_variant_text";
  } else {
    colour_state = "variant_text";
  }

  return (
    <p id={text_size_state} className={colour_state}>
      {vText}
    </p>
  );
};

export default VariantText;
