import classes from "../../styles/GeneralUI/AppButton.module.css";

const AppButton = ({
  children,
  type,
  onClick,
  styleButton,
  styleP,
  icon,
  iconStyle,
}) => {
  return (
    <button
      className={"button_start"}
      style={styleButton}
      type={type || "button"}
      onClick={onClick}
    >
      {icon && (
        <img
          className={`${classes.icon} ${iconStyle}`}
          src={icon}
          alt="button_icon"
        ></img>
      )}
      <p
        style={styleP}
        className={classes.button_text}
      >
        {children}
      </p>
    </button>
  );
};

export default AppButton;
