import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import SubHeaderModal from "../modals/SubHeaderModal";
import SubheaderText from "../modals/SubHeaderTextModal";
import checkIcon from "../assets/checkIcon.png";

import firstImage from "../assets/gifs/1.gif";
import secondmage from "../assets/gifs/2.gif";
import thirdImage from "../assets/gifs/3.gif";

const Slideshow = () => {
  const images = [firstImage];

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: true,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
    autoplay: false,
  };

  return (
    <Slide {...properties}>
      <div className="each-slide-effect">
        <div>
          <DialogContent style={{ overflow: "scroll" }}>
            <SubHeaderModal>What is SiWord?</SubHeaderModal>
            <SubheaderText>
              <span className="modal_siSpan_color">SiWord</span> is an intellectual game focusing on
              brain-functioning, knowledge and fun!
            </SubheaderText>

            <SubHeaderModal>Golden SiRules:</SubHeaderModal>
            <SubheaderText icon={checkIcon}>
              Answer 7 <span className="modal_siSpan_color">SiQuestions</span> and collect as many{" "}
              <span className="modal_siSpan_color">SiClues</span> as you can to find the{" "}
              <span className="modal_siSpan_color">SiWord</span> of today.
            </SubheaderText>
            <SubheaderText icon={checkIcon}>
              First <span className="modal_siSpan_color">SiQuestion</span> requires{" "}
              <span className="modal_span_color">no answer</span>.
            </SubheaderText>
            <SubheaderText icon={checkIcon}>
              First <span className="modal_siSpan_color">siClue</span> is given by the{" "}
              <span className="modal_siSpan_color">SiWord</span>.
            </SubheaderText>
            <SubheaderText icon={checkIcon}>
              Read the <span className="modal_siSpan_color">SiStory</span> of the day, it could be{" "}
              <span className="modal_span_color">useful</span> to find the{" "}
              <span className="modal_siSpan_color">SiWord</span>.
            </SubheaderText>
            <SubheaderText icon={checkIcon}>
              Every <span className="modal_span_color">first</span> letter of the{" "}
              <span className="modal_span_color">right</span> answer is a{" "}
              <span className="modal_siSpan_color">siClue</span> in right order.
            </SubheaderText>
            <SubheaderText icon={checkIcon}>
              <span className="modal_siSpan_color">SiWord</span>,{" "}
              <span className="modal_siSpan_color">SiQuestions</span> and{" "}
              <span className="modal_siSpan_color">SiStory </span>
              are <span className="modal_span_color">updated</span> daily. 12:00 AM
            </SubheaderText>
          </DialogContent>
        </div>
      </div>
      <div className="each-slide-effect each-slide-effect-1">
        <div style={{ backgroundImage: `url(${images[0]})` }}></div>
      </div>
    </Slide>
  );
};

export default Slideshow;
