import { useEffect, useState } from "react";
import classes from "../../styles/GeneralUI/ModalSubHeaderText.module.css";

const ModalSubHeaderText = ({
  children,
  icon,
  className,
  single_text_style,
  textStyle,
}) => {
  return (
    <div
      style={single_text_style}
      className={`${classes.sub_header_elements__container} ${className}`}
    >
      {icon && <span className={classes.icon}>{icon}</span>}
      <p
        className={
          icon ? classes.sub_header_text_withIcon : classes.sub_header_text
        }
        style={textStyle}
      >
        {children}
      </p>
    </div>
  );
};

export default ModalSubHeaderText;
