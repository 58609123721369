import React, { useContext, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MydModalWithGrid from "../modals/MydModalWithGrid";
import classes from "../styles/OtherButtons/LoginButton.module.css";
import { NavItem, Button, UncontrolledDropdown, DropdownMenu, DropdownItem } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { AppContextProvider } from "../Home";
import insertStatistic from "../api/requests";
// import insertStatistic from "../api/requests";
// import sdas from '../api/requests'
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const {
    gamesPlayed,
    gamesWon,
    correctQuizAnswers,
    userGuess,
    guessIsCorrect,
    lastPlaydate,
    gamesPlayedToday,
  } = useContext(AppContextProvider);

  const toggle = () => setIsOpen(!isOpen);

  const [modalShow, setModalShow] = useState(false);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     insertStatistic(
  //       user.sub,
  //       user.nickname,
  //       gamesPlayed,
  //       gamesWon,
  //       correctQuizAnswers,
  //       userGuess,
  //       parseInt(+guessIsCorrect),
  //       lastPlaydate
  //     );
  //     // localStorage.clear();
  //   }
  // }, [isAuthenticated]);

  return (
    <div>
      {!isAuthenticated && (
        <NavItem>
          <Button
            id="qsLoginBtn"
            color="primary"
            className={classes.login_button}
            // className="btn-margin"
            onClick={() => loginWithRedirect()}
          >
            Log in
          </Button>
        </NavItem>
      )}
      {isAuthenticated && (
        <>
          <div>
            <FontAwesomeIcon icon="user" className="mr-3" /> Profile
          </div>
          <UncontrolledDropdown nav inNavbar>
            <DropdownMenu>
              <div
                className={classes.profile_nav}
                // onClick={() => setModalShow(true)}
              >
                <DropdownItem header>{user.nickanme} </DropdownItem>
              </div>
              {<MydModalWithGrid username={user.sub} />}

              <DropdownItem
                tag={RouterNavLink}
                to="/"
                className="dropdown-profile"
                activeclassname="router-link-exact-active"
              />

              <DropdownItem
                className={classes.login_button}
                id="qsLogoutBtn"
                onClick={() => logoutWithRedirect()}
              >
                <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}
    </div>
  );
};

export default NavBar;
