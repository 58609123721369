import classes from "../styles/routePages/privacyPage.module.css";

const TextContainer = ({ textNumber, children }) => {
  return (
    <div className={classes.text_container}>
      <div className={classes.text}>{textNumber}</div>
      <div className={classes.text}>{children}</div>
    </div>
  );
};

const PrivacyPolicy = () => {
  return (
    <div className={classes.page}>
      <style>{css}</style>

      <div className={classes.header}>
        Website and Application Terms of Use
      </div>
      <div className={classes.dateText}>
        Date of last revision: [27/12/2022]
      </div>
      <div className={classes.texts__container}>
        <TextContainer textNumber="1.">
          Thank you for using www.siword.com (hereinafter referred to as
          “SiWord”). The present Terms of Use (hereinafter referred to as the
          “Terms”), covers and regulates all the terms & conditions of use and
          access for the website and application provided by “SneakPeak” LLC, a
          company registered and existing in the Republic of Azerbaijan, with
          the tax Identification no. 1604288151. You, as a user, (hereafter “You” or the “User”)
          shall be deemed to have accepted, agreed, acknowledged and bound by
          these Terms upon (i) using and (ii) registering.
        </TextContainer>
        <TextContainer textNumber="2.">
          SiWord remains registration free unless you choose to register. SiWord
          asks you to register only for the purposes of including you to the
          ranking and sending you the reports on a daily basis if you choose so.
          However, SiWord leaves the decision to you. SiWord does not use your
          email or nickname for any other purpose.
        </TextContainer>
        <TextContainer textNumber="3.">
          You should be fully aware of the fact that SiWord is an intellectual
          game focusing on brain-functioning, knowledge, and fun. The nickname
          and email address shall not contain (i) bad word in any language, (ii)
          any kind of provocative word, (iii) any kind of word that can be
          related to the sensitive issues. SiWord reserves the right to monitor
          the nicknames registered in SiWord and remove the nickname or account
          which is against these Terms. The Users are also encouraged to email
          us any content which they believe, breaches these Terms.
        </TextContainer>
        <TextContainer textNumber="4.">
          The User accepts that these Terms reflects his/her rights and
          obligations. By playing or registering, the User further acknowledges
          that the questions and stories used in SiWord might also have a wrong
          answer or basis. If you experience such an issue, you are encouraged
          to email us for further information or correction.
        </TextContainer>
      </div>
    </div>
  );
};

const css = `
    body {
       height: 100%;
    }
    body > div {
      height: 100%;
      overflow: scroll;
  } 
`;

{
  /* <style>
body {
    height: 100%;
}

body > div {
    height: 100%;
    overflow: scroll;
}
</style> */
}

export default PrivacyPolicy;
