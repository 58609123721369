import { useContext } from "react";
import { motion } from "framer-motion";
import { QuizContext } from "../../../SiQuiz/Quiz";

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3.5"
    stroke="white"
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => {
  const { quizIsFinished } = useContext(QuizContext);

  return (
    <button
      style={quizIsFinished ? { zIndex: 0 } : {}}
      className="hamburger_button"
      onClick={toggle}
    >
      <svg width="30" height="30" viewBox="0 0 30 25">
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 28 2.5" },
            open: {
              d: "M 3 16.5 L 17 2.5",
            },
          }}
        />
        <Path
          d="M 2 9.423 L 22 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 15 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
        />
      </svg>
    </button>
  );
};
