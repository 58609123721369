import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AppContextProvider } from "../../Home";
import useSiword from "../../hooks/useSiword";
import classes from "../../styles/SiWord/SiWord.module.css";
import ClueLettersBox from "../GeneralUI/Header/ClueLettersBox";
import Keyboard from "./Keyboard";
import WordsBoard from "./WordsBoard";
import PositionedSnackbar from "../../snackbar/snackbar";
import moment from "moment";
import IntersectionLine from "../GeneralUI/Header/IntersectionLine";

export const SiWordContext = createContext();
let correct_word = "";
let has_word = false;

const yourDate = new Date();
var NewDate = moment(new Date(), "DD-MM-YYYY").format();
NewDate = NewDate.split("T")[0];

function fetchWord() {
  axios
    .get("https://apisiword.siword.com/site/correctword?", {
      params: {
        date: NewDate,
        status: 1,
      },
    })
    .then((res) => {
      correct_word = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

const SiWord = ({ currentRow, hasCorrectGuess }) => {
  const [name, updateWordState] = useState(false);

  const { clueLettersBoard, setClueLettersBoard, setGameOver } =
    useContext(AppContextProvider);
  const {
    currentRowTry,
    myGuess,
    handleLaptopKeyboardTyping,
    handleCustomKeyboard,
    guesses,
    guessIsCorrect,
    keyState,
    animationFinished,
    setAnimationFinished,
    opacity,
    guessOnceClicked,
    wordExists,
    word_false,
    hitEnter,
  } = useSiword(correct_word, setGameOver);

  const { get_word } = useSiword();
  const [init, setInit] = useState(false);

  useEffect(() => {
    fetchWord();
    window.addEventListener("keyup", handleLaptopKeyboardTyping);
    return () =>
      window.removeEventListener("keyup", handleLaptopKeyboardTyping);
  }, [handleLaptopKeyboardTyping]);

  useEffect(() => {
    currentRow(currentRowTry);
    hasCorrectGuess(guessIsCorrect);
  }, [currentRowTry, guessIsCorrect, guesses]);

  return (
    <SiWordContext.Provider
      value={{
        clueLettersBoard,
        setClueLettersBoard,
        handleCustomKeyboard,
        keyState,
        guessIsCorrect,
        animationFinished,
        setAnimationFinished,
        opacity,
        guessOnceClicked,
        wordExists,
        currentRowTry,
        word_false,
        updateWordState,

        // guesses,
      }}
    >
      <div className={classes.intersection_line__container}>
        <IntersectionLine />
      </div>
      <div className={classes.siword_container}>
        <div className={classes.clue_letters_box__container}>
          <div className={classes.clue_letters_box}>
            <ClueLettersBox guesses={guesses} />
            {word_false && (
              <PositionedSnackbar
                openSnack={word_false}
                message="Not in words list"
              />
            )}
          </div>
        </div>

        <div className={classes.game_field_container}>
          <div className={classes.words_board__container}>
            <WordsBoard
              currentRowTry={currentRowTry}
              myGuess={myGuess}
              guesses={guesses}
              // hitEnter={hitEnter}
            />
          </div>

          <div
            className={classes.keyboard_container}
            // onKeyDown={handleLaptopKeyboardTyping}
          >
            <Keyboard />
          </div>
          {/* <div className={quizClasses.menu_box__container}>
            <MenuBox />
          </div> */}
        </div>
      </div>
    </SiWordContext.Provider>
  );
};

export default SiWord;
