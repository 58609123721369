import * as React from "react";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import thirdIcon from "../assets/3-1.png";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import "../styles/ModalStyles/StatisticsModalNonRegister.css";
import StatNonRegisterStats from "./StatNonRegisterStats";

import StatWinner from "./statistics/WinnerStats";
import classes from "../styles/GeneralUI/Modal/SiStatisticsToday.module.css";

// let played_games = 0;
// let win_rate = 0;

function createData(name, games, rate) {
  return { name, games, rate };
}

// let rows = [];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [playedGames, setPlayedGames] = React.useState(0);
  const [winRate, setWinRate] = React.useState(0);
  const [rows, setRows] = React.useState([]);

  const [rows_winner, setWinnerRows] = React.useState([]);

  const [rows_winner_duration, setWinnerDuration] = React.useState([]);

  function fetchAllStats() {
    axios
      .get("https://apisiword.siword.com/site/alluserstatistics?")
      .then((res) => {
        // isShowSnack = res.data;
        setRows(res.data);
        // rows = res.data;

        //createData (res.data.username,res.data.played_games,res.data.win_rate);
        
      })
      .catch((err) => {
        console.log(err);
        
      });
  }

  function fetchUserStats(username) {
    axios
      .get("https://apisiword.siword.com/site/userstatistics?", {
        params: {
          username: username,
        },
      })
      .then((res) => {
        setPlayedGames(res.data.played_games);
        setWinRate(res.data.win_rate);
        // played_games = res.data.played_games;
        // win_rate = res.data.win_rate;
        console.log(res.data);
        
      })
      .catch((err) => {
        console.log(err);
        
      });
  }

  function fetchWinnerStats() {
    axios
      .get("https://apisiword.siword.com/site/winnerbyrange?")
      .then((res) => {
        setWinnerRows(res.data["winner_stats"]);
        
        setWinnerDuration(res.data["winner_stats"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let userclass = "";
  let winnerclass = "";

  function setClassName(value) {
    if (props.username === value) {
      return (userclass = "main_user");
    } else {
      return (userclass = "");
    }
  }

  function setClassNameWinner(value) {
    if (value >= "42") {
      return (winnerclass = "winner_user");
    } else {
      return (winnerclass = "");
    }
  }

  const handleClickOpen = () => {
    fetchUserStats(props.username);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchUserStats(props.username);
    fetchAllStats();
    fetchWinnerStats();
  };

  return (
    <div>
      <div
        className={classes.go_to_statistics__button}
        variant=""
        onClick={handleClickOpen}
      >
        Go To Statistics
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Statistics
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs"
              class="tab-header"
            >
              <Tab label="Statistics of Today" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <StatNonRegisterStats
              played_games={playedGames}
              win_rate={winRate}
            />
          </TabPanel>
        </Box>
      </Dialog>
    </div>
  );
}
