import Line from "./Line";
import classes from "../../../styles/SiQuiz/ClueLettersBox.module.css";
import { AppContextProvider } from "../../../App";
import { useContext } from "react";
import { QuizContext } from "../../SiQuiz/Quiz";
const boxNumbers = [0, 1, 2, 3, 4, 5, 6];

const ClueLettersBox = ({ style, className, guesses }) => {
  // const { correctBoxes } = useContext(QuizContext);

  return (
    <span className={`${classes.clues_box} ${className}`} style={style}>
      {boxNumbers.map((box, index) => {
        return <Line guesses={guesses} box={box} key={box.toString()} />;
      })}
    </span>
  );
};

export default ClueLettersBox;
