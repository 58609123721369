import * as React from 'react';
import Link from '@mui/material/Link';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';


export default function ButtonLink(props) {

  let icon_show = ''
  if(props.icon_names=="FacebookIcon"){
    icon_show = <FacebookIcon/>
  }
  if(props.icon_names=="TwitterIcon"){
    icon_show = <TwitterIcon/>
  }
  
  return (
    <div className='button_block'>
      <div>{icon_show}</div>
      <Link
        target="_blank"
        href={props.button_link}
      >   
        {props.button_title}
      </Link>
    </div>
  );
}