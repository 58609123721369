import axios from "axios";
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SiStoryModal from "./SiStoryModal";
import "../styles/ModalStyles/siStoryModal.css";
import TitleModal from "./TitleModal";
import SubheaderText from "./SubHeaderTextModal";
import "../styles/ModalStyles/SiReport.css";
import AppButton from "../Components/GeneralUI/AppButton";
import moment from "moment";

const yourDate = new Date();
var NewDate = moment(new Date(), "DD-MM-YYYY").format();

var YesterdayDate = moment(new Date(), "DD-MM-YYYY")
  .subtract(1, "days")
  .format();
NewDate = NewDate.split("T")[0];
var YDay = YesterdayDate.split("T")[0];



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "25px",
    backgroundColor: "#BEBDBD !important",
    overflow: "unset !important",
  },
  "& .MuiDialogContent-root": {
    paddingTop: "0",
    paddingBottom: "0",
    // textAlign: 'center'
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiButtonBase-root": {
    position: "absolute !important",
    right: "-10px !important",
    top: "-10px !important",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{
            backgroundColor: "#3565A3",
            border: "1.6px solid #D9D9D9",
            color: "#D9D9D9",
            fontSize: "50px",
          }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const [datarow, setData] = React.useState([]);

  const [dataword, setDataWord] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    axios
      .get("https://apisiword.siword.com/site/previousday?", {
        params: {
          date: YDay,
          status: 1,
        },
      })
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    axios
      .get("https://apisiword.siword.com/site/correctword?", {
        params: {
          date: YDay,
          status: 1,
        },
      })
      .then((res) => setDataWord(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <p onClick={handleClickOpen}>SiReport of yesterday</p>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          align="center"
        >
          <TitleModal>SiReport of yesterday</TitleModal>
        </BootstrapDialogTitle>
        <DialogContent>
          <SubheaderText classNameText="siReport_subheader_text">
            SiWord of Yesterday: {dataword}
          </SubheaderText>
          <SubheaderText classNameText="siReport_subheader_text">
            SiQuestions
          </SubheaderText>
          {datarow.length > 0 && (
            <SubheaderText classNameText="siReport_subheader_text">
              {datarow.map((item) => (
                
                <p key={item.id} name={item.question}>
                  <SubheaderText classNameText="siReport_subheader_text">
                    {item.question}
                  </SubheaderText>
                  {item.question_id === 0 ? 
                    <SubheaderText classNameText="siReport_subheader_text">
                    {item.demo} {":"} {item.siclue}
                  </SubheaderText>
                  :
                  <SubheaderText classNameText="siReport_subheader_text">
                    {item.demo} {":"} {item.answer}
                  </SubheaderText>
                  }
                </p>
              ))}
            </SubheaderText>
          )}

          <SubheaderText classNameText="siReport_subheader_text">
            <SiStoryModal
              button_title="SiStory of yesterday"
              date_added={YDay}
            />
          </SubheaderText>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}
