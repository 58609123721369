import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import "../styles/ModalStyles/StatisticsModal.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import shareIcon from "../assets/share 1.png";
import classes from "../styles/GeneralUI/Modal/SiStatisticsToday.module.css";
import { RWebShare } from "react-web-share";
import copy from "copy-to-clipboard";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import TitleModal from "./TitleModal";
import SubheaderText from "./SubHeaderTextModal";
import AppButton from "../Components/GeneralUI/AppButton";
import PositionedSnackbar from "../snackbar/snackbar";
import { ClassNames } from "@emotion/react";
import SiStatisticsToday from "../Components/GeneralUI/Modal/SiStatisticsToday";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "25px",
    backgroundColor: "#BEBDBD !important",
    overflow: "unset !important",
  },
  "& .MuiDialogContent-root": {
    paddingTop: "0",
    paddingBottom: "0",
    // textAlign: 'center'
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root": {
    padding: 0,
    paddingRight: "0.5rem",
    marginBottom: "3px",
  },
  "& .MuiIconButton-root": {
    position: "absolute !important",
    right: "-10px !important",
    top: "-10px !important",
  },
  // "& .css-i4bv87-MuiSvgIcon-root": {
  //   marginBottom: "3.1px",
  // },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{
            backgroundColor: "#3565A3",
            border: "1.6px solid #D9D9D9",
            color: "#D9D9D9",
            fontSize: "50px",
          }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ fontSize: "20px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const [check, setCheck] = React.useState(false);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthMonthsDigit = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  const d = new Date();
  let date_shared =
    monthMonthsDigit[d.getDate() - 1] +
    " " +
    monthNames[d.getMonth()] +
    " " +
    d.getFullYear();
  const copyTextForDesktop =
    "🗓 SiWord: " +
    date_shared +
    "\n\n" +
    "❓ SiAnswers: " +
    props.correctSiAnswers +
    "/7 \n" +
    "🧠 SiWord: " +
    props.currentRowNum +
    "/7 \n\n" +
    "    ❓" +
    "     " +
    "    🧠 " +
    "\n" +
    (() => {
      let td = [];
      let tr = [];

      for (let i = 1; i <= 7; i++) {
        if (i <= props.correctSiAnswers) {
          td.push(" 🟢🟢 " + i);
        } else {
          td.push(" ⚪️⚪️ " + i);
        }
        if (i <= props.currentRowNum) {
          tr.push(" 🟢🟢 " + "\n");
        } else {
          tr.push(" ⚪️⚪️ " + "\n");
        }
      }

      let td_tr = [];
      for (let j = 0; j <= 6; j++) {
        td_tr.push(td[j] + tr[j]);
      }

      return td_tr;
    })() +
    "\nhttps://www.siword.com \n" +
    "#siword";



  const copyText =
    "🗓 SiWord: " +
    date_shared +
    "\n\n" +
    "❓ SiAnswers: " +
    props.correctSiAnswers +
    "/7 \n" +
    "🧠 SiWord: " +
    props.currentRowNum +
    "/7 \n\n" +
    "    ❓" +
    "     " +
    "    🧠 " +
    "\n" +
    (() => {
      let td = [];
      let tr = [];

      for (let i = 1; i <= 7; i++) {
        if (i <= props.correctSiAnswers) {
          td.push(" 🟢🟢 " + i);
        } else {
          td.push(" ⚪️⚪️ " + i);
        }
        if (i <= props.currentRowNum) {
          tr.push(" 🟢🟢 " + "\n");
        } else {
          tr.push(" ⚪️⚪️ " + "\n");
        }
      }

      let td_tr = [];
      for (let j = 0; j <= 6; j++) {
        td_tr.push(td[j] + tr[j]);
      }
       
      return td_tr;
    })();
    
    

  if (snackbarOpen) {
    navigator.clipboard.writeText(copyTextForDesktop.replaceAll(",", ""));
  }

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    copy("Text");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const shareUrl = "http://demo.siword.com";
  const title = "SiWord";
  const siword_hashtag = "#siword";

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  var stat_value_text = "";
  if (props.currentRowNum === 1) stat_value_text = "st";
  if (props.currentRowNum === 2) stat_value_text = "nd";
  if (props.currentRowNum === 3) stat_value_text = "rd";

  return (
    <div className={classes.share_button__container}>
      <RWebShare
        data={{
          text: copyText.replaceAll(",", ""),
          url: "https://siword.com",
          title: "Statistics",
        }}
        sites={["whatsapp", "twitter", "telegram", "mail"]}
        closeText={"Close"}
        onClick={() => console.log("shared successfully!")}
      >
        <button class="share-button button_start" id="mobile">
          🔗 Share{" "}
        </button>
      </RWebShare>
      <button
        class="share-button button_start"
        id="desktop"
        onClick={() => {
          setSnackbarOpen(true);
        }}
      >
        🔗 Share{" "}
      </button>
      {snackbarOpen && (
        <PositionedSnackbar
          hideDuration={2000}
          message="Copied To Clipboard "
          openSnack={snackbarOpen}
          onClose={handleCloseSnackBar}
        />
      )}
    </div>
  );
}
