import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import ErrorPage from "./routePages/ErrorPage";
import PrivacyPolicy from "./routePages/PrivacyPolicy";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-Y5L6MR2E8G"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
