import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { createAuth0Client } from "@auth0/auth0-spa-js";

import history from "./utils/history";
import { getConfig } from "./config";

const root = ReactDOM.createRoot(document.getElementById("root"));

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

// const auth0 = await createAuth0Client({
//   domain: config.domain,
//   client_id: config.clientId,
//   cacheLocation: 'localstorage',
//   useRefreshTokens: true
//   });

//   // Logging-in will automatically request the offline_access scope
//   // and store the resulting refresh token
//   auth0.loginWithRedirect();

//   // Silently refreshing the access token will use the /token endpoint
//   // with ‘refresh_token’ grant and the refresh token from the cache
//   await auth0.getTokenSilently();

root.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorker.unregister();
