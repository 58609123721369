import "../styles/ModalStyles/InfoModal.css";

const TitleModal = ({ children, style, className }) => {
  return (
    <p style={style} className={`${"title"} ${className}`}>
      {children}
    </p>
  );
};

export default TitleModal;
