import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { flexbox } from "@mui/system";
import { Dialog, DialogTitle, Portal } from "@mui/material";

export default function PositionedSnackbar({
  message,
  openSnack,
  onClose,
  hideDuration,
}) {
  const [state, setState] = React.useState({
    open: openSnack,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  //   const handleClick = (newState) => () => {
  //     setState({ open: true, ...newState });
  //   };

  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };

  return (
    <div>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          ContentProps={{
            sx: {
              fontFamily: "Nunito",
              fontSize: "1.4rem",
              justifyContent: "center",
              zIndex: "1000000 !important",
            },
          }}
          onClose={onClose}
          message={message}
          key={vertical + horizontal}
          autoHideDuration={hideDuration}
        />
      </Portal>
    </div>
  );
}
