import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material";
import Menu from "@mui/material/Menu/Menu";
import { ClassNames } from "@emotion/react";
import classes from "../../styles/GeneralUI/FaqPage.module.css";
import { TiArrowLeftThick } from "react-icons/ti";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p variant="outlined" onClick={handleClickOpen}>
        FAQ
      </p>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.faq_dialog}
      >
        <div className={classes.faq_header}>
          <p className={classes.goBack} onClick={handleClose}>
            <TiArrowLeftThick style={{ fontSize: "2rem" }} />
          </p>
          <p className={classes.faq_text}>Frequently Asked Questions</p>
        </div>

        <div className={classes.faq_body}>
          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">Is SiWord free?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">
                {" "}
                SiWord is totally free, and the registration is only needed for technical reasons if
                you wish to be a part of statistical competition called “siReward”
              </p>
            </p>
          </div>

          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">Is SiWord add-free?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">Yes, it is totally add-free</p>
            </p>
          </div>

          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">Can I play SiWord without registration?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">
                Yes. Registration is needed only for technical reasons to calculate the statistics
                to determine the winners of SiReward.
              </p>
            </p>
          </div>

          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">Why was the SiWord created?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">
                SiWord aims to mix and develop the knowledge, fun and brain-functioning.
              </p>
            </p>
          </div>

          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">How my data will be used?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">
                SiWord asks only for email while registration and you will receive no further email
                except for validating.
              </p>
            </p>
          </div>

          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">How can I know that the question or answer is right?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">
                You can always validate and let us know if you see any wrong answer or question
                through our email.
              </p>
            </p>
          </div>

          <div className={classes.question_answer_container}>
            <p class="faq_question">
              <p class="faq_letter">Q.</p>
              <p class="faq_quest">What is SiStory?</p>
            </p>
            <p class="faq_answer">
              <p class="faq_letter">A.</p>
              <p class="faq_quest_b">It is a short factual story per day.</p>
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
const StyleMenu = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    minWidth: 340,
    border: "1px solid #ECEFF1",
    boxShadow: "0px 0px 5px rgba(112, 129, 163, 0.102109)",
  },
}));
