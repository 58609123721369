import classes from "../../../styles/SiQuiz/ClueLettersInitial.module.css";
import { motion } from "framer-motion";

const ClueLettersInitial = ({ clue }) => {

  return (
    <div className={classes.clue_initial_container}>
      <motion.p
        //   initial={{ opacity: 0, scale: 0.5 }}
        //   animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
        //   transition={{ duration: 1 }}
        // ref={letterRef}
        className={classes.clue_initial}
      >
        {clue}
      </motion.p>
    </div>
  );
};

export default ClueLettersInitial;
