import * as React from "react";
import classes from "../../../styles/SiQuiz/GameName.module.css";
import MenuBox from "../MenuBox";

const GameName = ({ sendDataToParent }) => {
  return (
    <div className={classes.game_header_container}>
      <div className={classes.empty_div}></div>
      <div className={classes.game_name__container}>
        <p className={classes.game_name}>SiWord</p>
      </div>
      <div className={classes.menubox__container}>
        <MenuBox />
      </div>
    </div>
  );
};

export default GameName;
