import { useContext } from "react";
import SiRow from "./SiRow";
import { SiWordContext } from "./SiWord";

const WordsBoard = ({ myGuess, guesses, currentRowTry, hitEnter }) => {
  const {
    guessIsCorrect,
    animationFinished,
    setAnimationFinished,
    wordExists,
  } = useContext(SiWordContext);

  return (
    <div>
      {guesses &&
        guesses.map((guess, index) => {
          return (
            <SiRow
              key={index}
              guess={guess}
              myCurGuess={myGuess}
              guessIndex={index}
            />
          );
        })}
      {currentRowTry === 0 && <SiRow myCurGuess={myGuess} />}
      {!guessIsCorrect && currentRowTry !== 7 && animationFinished && (
        <SiRow myCurGuess={myGuess} />
      )}
    </div>
  );
};

export default WordsBoard;
