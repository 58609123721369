import classes from "../../../styles/GeneralUI/Modal/SiStatisticsTodayLevel.module.css";

const SiStatisticsTodayLevel = ({
  leftText,
  rightText,
  level,
  leftLevelColour,
  rightLevelColour,
  correctLeftTextColour,
  correctRightTextColour,
}) => {
  return (
    <div className={classes.level_container}>
      <div className={classes.left_elements__sontainer}>
        <p
          className={classes.level_text}
          id={correctLeftTextColour && classes["correct_text"]}
        >
          {leftText}
        </p>
        <div
          className={classes.left_view}
          id={leftLevelColour && classes["correct"]}
        ></div>
      </div>

      <div className={classes.level_number}>{level}</div>

      <div className={classes.right_elements__sontainer}>
        <div
          className={classes.right_view}
          id={rightLevelColour && classes["correct"]}
        ></div>
        <p
          className={classes.level_text}
          id={correctRightTextColour && classes["correct_text"]}
        >
          {rightText}
        </p>
      </div>
    </div>
  );
};

export default SiStatisticsTodayLevel;
