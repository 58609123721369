import classes from "../../styles/GeneralUI/MenuBox.module.css";
import firstIcon from "../../assets/1-1.png";
import active_firstIcon from "../../assets/1-2.png";
import secondIcon from "../../assets/2-1.png";
import active_secondIcon from "../../assets/2-2.png";
import thirdIcon from "../../assets/3-1.png";
import active_thirdIcon from "../../assets/3-2.png";
import fourthIcon from "../../assets/4-1.png";
import active_fourthIcon from "../../assets/4-2.png";
import SiQuizModal from "../GeneralUI/SiQuizModal";
import { useState } from "react";
import InfoModalWelcome from '../../modals/InfoModalWelcome';
import InfoModal from '../../modals/InfoModal';
import StatisticsModal from '../../modals/Statistics';
import SiStoryModal from "../../modals/SiStoryModal";
import StatisticsModalNonRegister from "../../modals/StatisticsModalNonRegister";

import { useAuth0 } from "@auth0/auth0-react";


const MenuBox = () => {
  
  const {
    user,
    isAuthenticated,
  } = useAuth0();

  if(isAuthenticated){
    
    return (
      <div className={classes.menu}> 
        <InfoModalWelcome />
        <InfoModal />
        <StatisticsModal  username = {user.sub} />
        <SiStoryModal />
      </div>
    );
  }else{
    return (
      <div className={classes.menu}> 
        <InfoModal />
        <InfoModalWelcome />
        <StatisticsModalNonRegister />
        <SiStoryModal />
      </div>
    );
  }

  
};

export default MenuBox;
