import { useContext } from "react";
import "../../../styles/SiQuiz/Number.css";
import { QuizContext } from "../Quiz";

const Number = ({ number, correct, wrong }) => {
  const { currentQuestion, answerSelected } = useContext(QuizContext);

  let number_colour_state = "";
  let line_colour_state = "";

  if (number === currentQuestion + 1 && !answerSelected) {
    number_colour_state = "number_active";
  }

  if (number === currentQuestion + 1 && answerSelected) {
    number_colour_state = "number_choosen";
  }

  if (correct) {
    number_colour_state = "number_correct";
    line_colour_state = "number_container_active";
  } else if (wrong) {
    number_colour_state = "number_wrong";
    line_colour_state = "number_container_active";
  }

  return (
    <div
      className={number !== 7 ? "number_container" : "last_number"}
      id={line_colour_state}
    >
      <p className="number" id={number_colour_state}>
        {number}
      </p>
    </div>
  );
};

export default Number;
