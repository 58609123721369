import { useEffect, useState } from "react";
import Keys from "../data/keys.json";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
const max_column_width = 7;
const max_row_width = 7;
//let has_word = false;

const yourDate = new Date();
var NewDate = moment(new Date(), "DD-MM-YYYY").format();
NewDate = NewDate.split("T")[0];

const useSiword = (correct_word, setGameOver, has_word) => {
  const [currentRowTry, setcurrentRowTry] = useState(0);
  const [myGuess, setMyGuess] = useState("");
  const [guessIsCorrect, setGuessIsCorrect] = useState(false);

  const [has_word_data, setHasword] = useState(false);

  const [guesses, setGuesses] = useState([]);
  const [keyState, setKeyState] = useState({});
  const [animationFinished, setAnimationFinished] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [guessOnceClicked, setGuessOnceClicked] = useState(false);
  const [wordExists, setWordExists] = useState(false);
  const [user_name, setUsername] = useState();
  const [nickname, setNickname] = useState();

  const [ip, setIP] = useState("");
  const [localData, setLocaldata] = useState([]);
  const [correctAnswerCount, setCorrectAnswers] = useState(0);

  const { user, isAuthenticated } = useAuth0();

  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [gamesWon, setGamesWon] = useState(0);

  const [hitEnter, setEnter] = useState(false);

  //creating function to load ip address from the API

  const getUserIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const updateDatatoLocalStorage = (localData) => {
    localStorage.setItem("items", JSON.stringify(localData));
  };

  const addDatatoLocalStorage = (gamesPlayed, gamesWon) => {
    localStorage.setItem("gamesPlayed", gamesPlayed);
    localStorage.setItem("gamesWon", gamesWon);
  };

  const getLocalData = () => {
    const items = localStorage.getItem("questions_end");

    const item_played = localStorage.getItem("gamesPlayed");
    const item_won = localStorage.getItem("gamesWon");
    if (items) {
      setCorrectAnswers(items);
    }
    if (item_played && item_won) {
      setGamesPlayed(item_played);
      setGamesWon(item_won);
    }
  };

  function insertStatistic(
    username,
    nickname,
    played_games,
    win_rate,
    correct_answers,
    correct_guess,
    played_today,
    last_play_date
  ) {
    axios
      .post("https://apisiword.siword.com/site/statistics?", null, {
        params: {
          username: username,
          nickname: nickname,
          played_games: played_games,
          win_rate: win_rate,
          correct_answers: correct_answers,
          correct_guess: correct_guess,
          played_today: played_today,
          last_play_date: last_play_date,
        },
      })
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  }

  const [word_false, updateWordState] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getLocalData();
      setUsername(user.sub);
      setNickname(user.nickname);
    } else {
      getUserIp();
      getLocalData();
    }
    if (myGuess.length > 0) {
      setGuessOnceClicked(true);
    }
    if ((guessIsCorrect || currentRowTry === 7) && animationFinished) {
      setTimeout(() => {
        if (isAuthenticated) {
          if (!guessIsCorrect) {
            insertStatistic(
              user_name,
              nickname,
              1,
              0,
              correctAnswerCount,
              0,
              1,
              NewDate
            );
            setLocaldata({
              user_data: "registred user",
              url: "http://localhost:3000/",
              user_ip: ip,
              user_guess: 0,
              question_answered: correctAnswerCount,
              last_time_played: NewDate,
              find_answer_today: guessIsCorrect,
              games_played: gamesPlayed,
              won_rate: gamesWon,
            });
          } else {
            setLocaldata({
              user_data: "registred user",
              url: "http://localhost:3000/",
              user_ip: ip,
              user_guess: currentRowTry,
              question_answered: correctAnswerCount,
              last_time_played: NewDate,
              find_answer_today: guessIsCorrect,
              games_played: gamesPlayed,
              won_rate: gamesWon,
            });
            insertStatistic(
              user_name,
              nickname,
              1,
              1,
              correctAnswerCount,
              currentRowTry,
              1,
              NewDate
            );
          }
        } else {
          if (!guessIsCorrect) {
            updateDatatoLocalStorage(localData);
            addDatatoLocalStorage(
              parseInt(gamesPlayed) + 1,
              parseInt(gamesWon)
            );
            setLocaldata({
              user_data: "nonregistred user",
              url: "http://localhost:3000/",
              user_ip: ip,
              user_guess: 0,
              question_answered: correctAnswerCount,
              last_time_played: NewDate,
              find_answer_today: guessIsCorrect,
              games_played: gamesPlayed,
              won_rate: gamesWon,
            });
          }
          if (guessIsCorrect) {
            setLocaldata({
              user_data: "nonregistred user",
              url: "http://localhost:3000/",
              user_ip: ip,
              user_guess: currentRowTry,
              question_answered: correctAnswerCount,
              last_time_played: NewDate,
              find_answer_today: guessIsCorrect,
              games_played: gamesPlayed,
              won_rate: gamesWon,
            });

            updateDatatoLocalStorage(localData);
            addDatatoLocalStorage(
              parseInt(gamesPlayed) + 1,
              parseInt(gamesWon) + 1
            );
          }
        }

        setGameOver(true);
      }, 2000);
    }
  }, [
    guessIsCorrect,
    currentRowTry,
    isAuthenticated,
    animationFinished,
    myGuess.length,
  ]);

  useEffect(() => {
    updateDatatoLocalStorage(localData);
  }, [localData]);

  function fetchExisitingWord(check_existing_word) {
    axios
      .get("https://apisiword.siword.com/site/wordbank?", {
        params: {
          check_word: check_existing_word,
        },
      })
      .then((res) => {
        has_word = res.data;
        setHasword(has_word);
        if (has_word) {
          updateWordState(true);
          return;
        } else {
          checkFinalGuessExistInWordsBank();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //username=&played_games=1&win_rate=1&played_today=1

  //.... Here we handle colour states of letters of added word  .....
  const handleFinalGuess = () => {
    const correctWord = correct_word;
    const guess = [...myGuess];

    const finalGuess = guess.map((letter) => {
      return { key: letter, colour_state: "wrong" };
    });

    finalGuess.forEach((obj, index) => {
      if (correctWord[index] === guess[index]) {
        obj.colour_state = "correct";
      } else if (correctWord.includes(guess[index])) {
        obj.colour_state = "almost_correct";
      }
    });

    return finalGuess;
  };

  // ........ Here we handle adding new guess to new row ......................

  const handleNextGuess = (final_guess) => {
    setAnimationFinished(false);

    if (myGuess === correct_word) {
      setGuessIsCorrect(true);
    }

    setGuesses((prev) => [...prev, final_guess]);

    setcurrentRowTry(currentRowTry + 1);
    setKeyState((prevUsedKeys) => {
      final_guess.forEach((guess) => {
        const current_colour_state = prevUsedKeys[guess.key];
        if (
          guess.colour_state === "correct" &&
          prevUsedKeys[guess.key] === "almost_correct"
        ) {
          prevUsedKeys[guess.key] = "beforeAlmostNowCorrect";
        } else if (
          guess.colour_state === "correct" &&
          current_colour_state !== "beforeAlmostNowCorrect"
        ) {
          prevUsedKeys[guess.key] = "correct";
        } else if (
          guess.colour_state === "almost_correct" &&
          current_colour_state !== "correct" &&
          current_colour_state !== "beforeAlmostNowCorrect"
        ) {
          prevUsedKeys[guess.key] = "almost_correct";
        } else if (
          guess.colour_state === "wrong" &&
          current_colour_state !== "correct" &&
          current_colour_state !== "almost_correct" &&
          current_colour_state !== "beforeAlmostNowCorrect"
        ) {
          prevUsedKeys[guess.key] = "wrong";
        }
      });
      return prevUsedKeys;
    });

    setMyGuess("");
    setGuessOnceClicked(false);
    setOpacity(opacity - 0.13);

    if (currentRowTry === 6 && myGuess !== correct_word) {
      setTimeout(() => {
        setAnimationFinished(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setAnimationFinished(true);
      }, 2200);
    }
  };

  // ...........................................................................
  // Here we handle laptop keyboard typing
  const handleLaptopKeyboardTyping = ({ key }) => {
    if (animationFinished || currentRowTry === 0) {
      setEnter(true);
    }

    if (key === "Enter" && hitEnter) {
      handleEnter();
      setEnter(false);
      return;
    }

    if (animationFinished || currentRowTry === 0) {
      Keys[1].letters.forEach((letter) => {
        if (letter.key.toUpperCase() === key.toUpperCase()) {
          if (myGuess.length < max_column_width) {
            setMyGuess((p) => p + key.toUpperCase());
          }
        }
      });

      if (key === "Backspace") {
        updateWordState(false);
        handleDelete();
        return;
      }
    }
  };

  // .............. Handle custom keyboard typing that we created.......
  const handleCustomKeyboard = (key) => {
    if (animationFinished || currentRowTry === 0) {
      if (typeof key === "object") {
        updateWordState(false);
        handleDelete();
        return;
      }

      if (key === "Delete") {
        updateWordState(false);
        handleDelete();
        return;
      }
      if (key === "Enter") {
        handleEnter();
        return;
      }

      if (myGuess.length < max_column_width) {
        setMyGuess((p) => p + key.toUpperCase());
      }
    }
  };

  //............. ENTER HANDLER FUNCION ...............
  const handleEnter = () => {
    setEnter(true);
    if (myGuess.length < max_column_width) return;
    if (currentRowTry > max_row_width - 1) return;
    fetchExisitingWord(myGuess);
    return;
    // }

    // setEnter(true);
    // if (hitEnter) {
    //   // setEnter(false);
    //   // if (currentRowTry > max_row_width - 1) {
    //   //   return;
    //   // }

    //   // if (myGuess.length === 7) {
    //   //   fetchExisitingWord(myGuess);

    //   //   if (!word_false) {
    //   //     return;
    //   //   }
    //   // } else if (myGuess.length < max_column_width) {
    //   //   return;
    //   // }
    // } else {
    //   return;
    // }
  };

  //............. Delete HANDLER FUNCION ...............
  const handleDelete = () => {
    setHasword(false);
    setMyGuess((p) => p.slice(0, -1));
    return;
  };

  //// .........  CHECK IF ADDED WORD EXIST IN WORDS BANKS ..........//////

  const checkFinalGuessExistInWordsBank = () => {
    setWordExists(true);
    handleNextGuess(handleFinalGuess());
  };

  return {
    currentRowTry,
    myGuess,
    handleLaptopKeyboardTyping,
    guesses,
    guessIsCorrect,
    handleCustomKeyboard,
    keyState,
    animationFinished,
    setAnimationFinished,
    opacity,
    guessOnceClicked,
    wordExists,
    word_false,
    // hitEnter,
  };
};

export default useSiword;
