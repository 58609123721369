import classes from "../styles/routePages/errorPage.module.css";

const ErrorPage = () => {
  return (
    <>
      <style>{css}</style>
      <div className={classes.error_message}>ERROR! PAGE NOT FOUND :(</div>;
    </>
  );
};

const css = `
    body {
       height: 100%;
    }
    body > div {
      height: 100%;
  } 
`;

export default ErrorPage;
