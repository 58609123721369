import "../../styles/SiWord/Keyboard.css";
import Keys from "../../data/keys.json";
import Key from "./Key";
import Delete from "../../assets/delete.png";


const Keyboard = () => {
  return (
    <div className="keyboard">

      <div class="keyboard-rows">

      {Keys[0].firstLine.map((key, index) => {
        return (
          <div className="row">
            <Key key={index} k={key} />
          </div>
        );
      })}

      </div>
     
      <div class="keyboard-rows keyboard-rows-second">
       {Keys[0].secondLine.map((key, index) => {
        return (
          <div className="row">
            <Key key={index} k={key} />
          </div>
        );
      })}
      </div>
      
      <div class="keyboard-rows">
      {Keys[0].thirdLine.map((key, index) => {
        return (
          <div className="row">
            {
              key === "Delete" ? <Key key={index} k={ <img src={Delete} className={"deleteButton"} /> } /> : <Key key={index} k={key} /> 
            }
            
          </div>
        );
      })} 
      </div>
    </div>
  );
};




export default Keyboard;
