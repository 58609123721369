import axios from "axios";
import VariantLetter from "./VariantLetter";
import VariantText from "./VariantText";
import "../../../styles/SiQuiz/VariantBox.css";
import { useContext, useState } from "react";
import { QuizContext } from "../Quiz";
import { motion } from "framer-motion";
import ClueLettersInitial from "./ClueLettersInitial";
import moment from "moment";

const VariantBox = ({ variantLetter, variantText }) => {
  const [clueIsVisible, setClueIsVisible] = useState(false);

  const [correct_answer, setAnswer] = useState();

  const [clue, setClue] = useState();

  const [question_number, setQuestionNumber] = useState();

  const yourDate = new Date();
  var NewDate = moment(new Date(), "DD-MM-YYYY").format();
  NewDate = NewDate.split("T")[0];

  function fetchUsers() {
    axios
      .get("https://apisiword.siword.com/site/checkanswer?", {
        params: {
          qustion_number: currentQuestion,
          letter: variantLetter,
          date: NewDate,
          status: 1,
        },
      })
      .then((res) => {
        setAnswer(res.data);
        setTimeout(checkCorrectAnswer(res.data), 100);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchClues() {
    axios
      .get("https://apisiword.siword.com/site/getsiclue?", {
        params: {
          qustion_number: currentQuestion,
          date: NewDate,
          status: 1,
        },
      })
      .then((res) => {
        setClue(res.data);
        handleClueLetters(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const {
    currentQuestion,
    setCurrentQuestion,
    selectedAnswer,
    setSelectedAnswer,
    className,
    setClassName,
    answerSelected,
    setAnswerSelected,
    setCorrectNums,
    setWrongNums,
    clueLettersBoard,
    setClueLettersBoard,
    clueLetterPosition,
    setClueLetterPosition,
    setQuizIsFinished,
    correctAnswerCount,
    setCorrectAnswerCount,
  } = useContext(QuizContext);

  const handleClueLetters = (value) => {
    const currentBoard = [...clueLettersBoard];

    setClueLetterPosition(currentQuestion);
    setClueLettersBoard(currentBoard);
    currentBoard[currentQuestion] = value;
  };

  const handleNext = () => {
    if (currentQuestion < 6) {
      setSelectedAnswer(null);
      setClassName("variant_box");
      setAnswerSelected(false);
      setCurrentQuestion(currentQuestion + 1);
      setClueIsVisible(false);
    }

    if (currentQuestion === 6) {
      setTimeout(() => {
        setQuizIsFinished(true);
      }, 1500);
    }
  };

  const handleAnimation = () => {
    setTimeout(() => {
      setClassName("variant_box white_box");
      setClueIsVisible(true);
    }, 1500);
    setTimeout(() => {}, 5000); // it must stay here
  };

  const variantClickHandler = () => {
    fetchUsers();

    setClassName("variant_box active");
    setSelectedAnswer(variantLetter);
    setAnswerSelected(true);
  };

  const checkCorrectAnswer = (value) => {

    if (variantLetter === value) {
      setClassName("variant_box correct");
      setCorrectAnswerCount(correctAnswerCount + 1);

      handleAnimation();

      setTimeout(() => {
        setCorrectNums((p) => [...p, currentQuestion + 1]);
      }, 400);

      setTimeout(() => {
        fetchClues();
      }, 700);

      setTimeout(handleNext, 2700);
    } else {
      
      setClassName("variant_box wrong");
      setTimeout(() => {
        setWrongNums((p) => [...p, currentQuestion + 1]);
      }, 400);
      setTimeout(handleNext, 1500);
    }
  };

  return (
    <motion.li
      whileHover={{
        scale: 1.02,
      }}
      className={selectedAnswer === variantLetter ? className : "variant_box"}
      onClick={() => {
        !answerSelected && variantClickHandler();
      }}
    >
      {!clueIsVisible && <VariantLetter vLetter={variantLetter} />}
      {!clueIsVisible && (
        <VariantText vText={variantText} vLetter={variantLetter} />
      )}
      {clueIsVisible && <ClueLettersInitial clue={clue} />}
    </motion.li>
  );
};

export default VariantBox;
