import { useContext } from "react";
// import classes from "../../styles/NumbersBox.module.css";
import classes from "../../../styles/SiQuiz/NumbersBox.module.css";
import Number from "./Number";
import { QuizContext } from "../Quiz";

const numbers = [1, 2, 3, 4, 5, 6, 7];

const NumbersBox = () => {
  const { correctNums, wrongNums } = useContext(QuizContext);

  return (
    <div className={classes.question_number}>
      {numbers.map((n) => {
        return (
          <Number
            number={n}
            key={n}
            correct={correctNums.includes(n)}
            wrong={wrongNums.includes(n)}
          />
        );
      })}
    </div>
  );
};

export default NumbersBox;
