import ClueLetter from "./ClueLetter";
import classes from "../../../styles/SiQuiz/Line.module.css";
import { useContext, useState } from "react";
import { AppContextProvider } from "../../../Home";
let disappering_lines = [];

const Line = ({ box, guesses }) => {
  const { clueLettersBoard, quizEnded } = useContext(AppContextProvider);
  // const [correctLetter, setCorrectLetter] = useState("")
  // const [correctLetterPos, setCorrectLetterPos] = useState(null)
  let correct_letter = "";
  let correct_letter_position = null;

  if (guesses) {
    if (guesses.length > 0) {
      const lastGuess = guesses[guesses.length - 1];
      lastGuess.forEach((e, index) => {
        if (e.colour_state === "correct") {
          correct_letter = e.key;
          correct_letter_position = index;
          if (quizEnded && !clueLettersBoard[box]) {
            clueLettersBoard[correct_letter_position] = correct_letter;
          }
        }
      });
    }
  }

  const clue_letter = clueLettersBoard[box];
  if (clueLettersBoard[box]) {
    if (!disappering_lines.includes(box)) {
      disappering_lines.push(box);
      disappering_lines.push(box - 1);
    }
  }

  disappering_lines.sort();
  //console.log(disappering_lines);

  return (
    <div className={classes.container}>
      <div
        className={classes.clue_letter_container}
        id={clue_letter ? classes["correct_box"] : {}}
      >
        <div
          className={classes.clue}
          id={clue_letter ? classes["correct_clue_container"] : {}}
        >
          <ClueLetter clueLetter={clue_letter} />
        </div>
      </div>

      <div
        className={classes.line_container}
        id={disappering_lines.includes(box) ? classes["no_line_container"] : {}}
        style={box === 6 ? { height: "0%", marginRight: "-10px" } : {}}
      >
        <div
          className={classes.line}
          id={
            disappering_lines.includes(box) ? classes["disappering_line"] : {}
          }
        />
      </div>
    </div>
  );
};

export default Line;
