import * as React from "react";
import { motion } from "framer-motion";
import "../../../../styles/Navbar/navstyles.css";
import NavBar from "../../../NavBar";
import { height } from "@mui/system";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

// const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ text, index }) => {
  //   const style = { border: `2px solid ${colors[i]}` };
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1 }}
      whileTap={{ scale: 0.95 }}
      className={index === 5 ? "newLi" : "li"}

      //   style={!isOpen.isOpen && { zIndex: -11110 }}
    >
      <div className="text-placeholder">{text}</div>
    </motion.li>
  );
};
