import { useContext } from "react";
import "../../styles/SiWord/Keyboard.css";
import { SiWordContext } from "./SiWord";
import { motion } from "framer-motion";

const Key = ({ k }) => {
  const { handleCustomKeyboard, keyState } = useContext(SiWordContext);

  let colour_state = "";
  colour_state = keyState[k];

  const handleClick = () => {
    handleCustomKeyboard(k);
  };

  // console.log("COL STATE: ", colour_state);

  return (
    <motion.div
      whileHover={{ backgroundColor: "lightgray" }}
      whileTap={{ scale: 1.02 }}
      className="key"
      id={colour_state}
      onClick={handleClick}
    >
      {k}
    </motion.div>
  );
};

export default Key;
