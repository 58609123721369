import DialogContent from "@mui/material/DialogContent";
import { useContext } from "react";
import { QuizContext } from "../SiQuiz/Quiz";
import { AppContextProvider } from "../../Home";
import Slideshow from "../../slide-preview/slideshowgame";

const addDatatoLocalStorage = (localData) => {
  localStorage.setItem("questions_end", localData);
};

const SiQuizModal = ({
  onClick,
  className,
  // onHandleGettingFilledClueLetters,
}) => {
  const { correctAnswerCount, quizIsFinished, clueLettersBoard } =
    useContext(QuizContext);
  const { setQuizEnded } = useContext(AppContextProvider);

  const startSiGameHandler = () => {
    // onHandleGettingFilledClueLetters(clueLettersBoard);
    setTimeout(() => {
      addDatatoLocalStorage(correctAnswerCount);
      setQuizEnded(true);
    }, 500);
  };

  return (
    <DialogContent
      style={{ overflow: "", padding: "0" }}
      class="slideshow-app slideshow-app-modal slideshow-app-clues"
    >
      <Slideshow />
    </DialogContent>
  );
};

export default SiQuizModal;
